import { PropsWithChildren, useContext } from "react";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { Box } from "@gocardless/flux-react";

import { EnforceMFAWrapper } from "./mfa/EnforceMFAWrapper";
import Banners from "./Banners";
import { ProposedAccountLinkWrapper } from "./proposed-account-link/ProposedAccountLinkWrapper";

import { IdentityCheckWrapper } from "src/components/authentication/identityCheck/IdentityCheckWrapper";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation"; // Import the hook
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants"; // Import the feature flag
import { useGetPrimaryCreditorResponse } from "src/queries/creditor";
import { FullStory } from "src/technical-integrations/fullstory";
import { UserLocaleWrapper } from "src/components/i18n";
import { useAccessToken } from "src/common/authorisation";
import { GlobalState } from "src/components/global-state/GlobalStateProvider";
import { useTermsAccepted } from "src/components/authentication/termsAccepted/useTermsAccepted";
import Zendesk from "src/technical-integrations/zendesk/Zendesk";
import { Optimizely } from "src/technical-integrations/optimizely";
import Drift from "src/technical-integrations/drift/Drift";
import { BrazeProvider } from "src/technical-integrations/braze/BrazeProvider";

interface Props {
  enforceMFA?: boolean;
  enforceIdentityCheck?: boolean;
  bypassProposedLinkChecks?: boolean;
  withIntercom?: boolean;
  withZendesk?: boolean;
  children: React.ReactNode;
}

const AuthSync: React.FC<Props> = ({
  enforceMFA,
  enforceIdentityCheck,
  bypassProposedLinkChecks,
  withIntercom = true,
  children,
}) => {
  useAccessToken();
  useTermsAccepted();
  const { data } = useUserShowSelf();
  const { bannersHeight } = useContext(GlobalState);

  // Use the Optimizely hook to get the feature flag value

  if (!data) {
    return null;
  }

  return (
    <EnforceMFAWrapper enforceMFA={enforceMFA}>
      <Optimizely>
        <FullStory withOptimizely />
        <IdentityCheckWrapper enforceIdentityCheck={enforceIdentityCheck}>
          <ProposedAccountLinkWrapper bypassChecks={bypassProposedLinkChecks}>
            <Banners />
            <UserLocaleWrapper>
              <BrazeProvider>
                <Box css={{ paddingTop: `${bannersHeight}px` }}>
                  {/* Render the determined SupportComponent */}
                  <SupportComponent withIntercom={withIntercom}>
                    {children}
                  </SupportComponent>
                </Box>
              </BrazeProvider>
            </UserLocaleWrapper>
          </ProposedAccountLinkWrapper>
        </IdentityCheckWrapper>
      </Optimizely>
    </EnforceMFAWrapper>
  );
};

export const SupportComponent: React.FC<
  PropsWithChildren<{ withIntercom?: boolean }>
> = ({ children }) => {
  const { isVariationOn, clientReady } = useOptimizelyVariation({
    flag: OptimizelyFlag.BI_ZENDESK_MIGRATION,
  });
  const { data: creditorResponse, isLoading } = useGetPrimaryCreditorResponse();
  if (!clientReady || isLoading) {
    return <>{children}</>;
  }

  if (creditorResponse?.creditors?.activated) {
    return <Drift>{children}</Drift>;
  }

  if (isVariationOn) {
    return <Zendesk creditor={creditorResponse?.creditors}>{children}</Zendesk>;
  }

  return <>{children}</>;
};

export default AuthSync;
